import { useState } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import { NavSvgComputer, NavSvgDart } from '../../../assets/svg/NavSvg';

const Header = () => {

    const navLinks = document.querySelectorAll('.link');
    const [navbar, setNavbar] = useState(false);

    navLinks.forEach(link => {
        link.addEventListener('click', () => {
            document.querySelector('.active')?.classList.remove('active');
            link.classList.add('active');
        });
    });

    function changeBackground() {
        return window.scrollY >= 100 ? setNavbar(true) : setNavbar(false);
    }
    window.addEventListener('scroll', changeBackground);

    return (
        <nav className= { 
                        navbar 
                            ? "navbar nav-bg-dark navbar-expand-lg fixed-top" 
                            : "navbar navbar-expand-lg fixed-top"
            }>
            <div className='container-fluid px-lg-5 px-3'>
                <a className='navbar-brand' href='#'>
                    <img className='navbar-logo' src={require('../../../assets/images/logo-vr-group 2.png')} alt='logo' />
                </a>
                <div className='d-flex gap-1'>
                    <a className='d-lg-none d-flex justify-content-center py-1 align-items-center'>
                        <img src={require('../../../assets/images/message-icon.png')} alt='message-icon' />
                    </a>
                    <button className='navbar-toggler border-0 shadow-none' type='button' data-bs-toggle='collapse'
                        data-bs-target='#navbarSupportedContent' aria-controls='navbarSupportedContent'
                        aria-expanded='false' aria-label='Toggle navigation'>
                        <span className='navbar-toggler-icon'></span>
                    </button>
                </div>
                <div className='collapse navbar-collapse' id='navbarSupportedContent'>
                    <ul className='navbar-nav nav-underline m-auto mb-2 mb-lg-0'>
                        <li className='nav-item'>
                            <Link to='/' className='nav-link link active' type='button' aria-current='page'>Inicio</Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/nosotros' className='nav-link link' type='button'>Nosotros</Link>
                        </li>
                        <li class="nav-item dropdown dropdown-nav">
                            <button type='button' class="nav-link  dropdown-toggle" data-bs-toggle="dropdown"> Servicios </button>
                            <div class="dropdown-menu">
                                <div className='dropdown-menu_child'>
                                    <div className="dropdown-menu_header">
                                        <NavSvgComputer/>
                                        <strong>Transformación digital y Tecnología</strong>
                                    </div>
                                    <ul>
                                        <li><Link to='/servicios'>Diseño de experiencia digital</Link></li>
                                        <li><Link to='/servicios'>Automatización de procesos</Link></li>
                                        <li><Link to='/servicios'>Soluciones tecnológica</Link></li>
                                        <li><Link to='/servicios'>Performance Digital</Link></li>
                                    </ul>
                                </div>
                                <div className='dropdown-menu_child'>
                                    <div className="dropdown-menu_header">
                                        <NavSvgDart/>
                                        <strong>Transformación digital y Tecnología</strong>
                                    </div>
                                    <ul>
                                        <li><Link to='/servicios'>Gestión y Control de Proyectos (PMO)</Link></li>
                                        <li><Link to='/servicios'>Gestión de Procesos (BPM)</Link></li>
                                        <li><Link to='/servicios'>Control de Gestión</Link></li>
                                        <li><Link to='/servicios'>Auditoría</Link></li>
                                        <li><Link to='/servicios'>Outsourcing de Profesionales</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li className='nav-item'>
                            <Link to='/' className='nav-link link' type='button'>Proyectos</Link>
                        </li>
                    </ul>
                </div>
                <a className='hablemos-button d-none d-lg-flex py-1 px-3 rounded-4 align-items-center gap-2'>
                    <span>Hablemos</span>
                    <img src={require('../../../assets/images/message-icon.png')} alt='' />
                </a>
            </div>
        </nav>
    );
};

export default Header;