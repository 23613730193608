import Slider from 'react-slick';
import { useState, useEffect } from 'react';
import './Experiencia-Digital.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ExperienciaDigital = () => {

    const [screenWidth, setScreenWidth] = useState(getCurrentDimension());

    const items = [
        {
            id: 1,
            icono: 'responsive.png',
            titulo: 'Discovery UX',
            descripcion: `No importa dónde te encuentres en el ciclo de desarrollo de un 
                        producto digital, podemos ayudarte a validar ideas y asegurarnos de que 
                        inviertas tiempo y energía en el momento correcto.`
        },
        {
            id: 2,
            icono: 'Ai-robotics-brain.png',
            titulo: 'Diseño de producto digital',
            descripcion: `Colaboramos con tu negocio y los equipos de desarrollo para prototipar y 
                        diseñar experiencias de usuario excepcionales y multiplataforma.`
        },
        {
            id: 3,
            icono: 'Vector.png',
            titulo: 'Laboratorio Digital',
            descripcion: `Convertimos tu producto digital en un canal vivo, manteniéndolo sincronizado 
                        a las necesidades de tu cliente.`
        }
    ]

    function getCurrentDimension() { 
        return {
            width: window.innerWidth
        }
    };

    useEffect(() => {
        const updateDimension = () => setScreenWidth(getCurrentDimension());
        window.addEventListener('resize', updateDimension);
        return (() => window.removeEventListener('resize', updateDimension));
    }, [screenWidth])


    let settings = {
        dots: true,
        infinite: true,
        speed: 800,
        slidesToShow: screenWidth.width < 992 ? 1 : 3,
        slidesToScroll: 1,
        arrows: false
    };

    return (
        <section id='experiencia-digital' className='experiencia-digital d-flex'>
            <div className='container'>
                <div className='vl m-auto'></div>
                <h1 className='experiencia-digital-text text-center'>Nuestras capacidades</h1>
                <h2 className='experiencia-digital-title text-center'>Full Digital Experience Design</h2>
                <div className='d-flex align-items-center justify-content-center mx-4 mt-4 overflow-hidden'>
                    <ul className='nav nav-fill nav-underline flex-nowrap overflow-auto' role='tablist'>
                        <li className='nav-item' role='presentation'>
                            <a className='nav-link active' type='button' id='diseño-tab' data-bs-toggle='pill' data-bs-target='#diseño'
                                role='tab' aria-controls='pills-diseño' aria-selected='true'>
                                Diseño y Experiencia Digital
                            </a>
                        </li>
                        <li className='nav-item'>
                            <a className='nav-link' type='button' id='research-tab' data-bs-toggle='pill' data-bs-target='#research'
                                role='tab' aria-controls='pills-research' aria-selected='false'>
                                Research UX
                            </a>
                        </li>
                        <li className='nav-item'>
                            <a className='nav-link' type='button' id='consultoria-tab' data-bs-toggle='pill' data-bs-target='#consultoria'
                                role='tab' aria-controls='pills-consultoria' aria-selected='false'>
                                Consultoría UX
                            </a>
                        </li>
                    </ul>
                </div>
                <div className='tab-content' id='pills-tabContent'>
                    <div className="tab-pane fade show active" id="diseño" role='tab-panel' aria-labelledby='diseño-tab'>
                        <p className='py-4 px-2 text-center'>
                            Descubrir las necesidades y oportunidades declaradas por tus usuarios, nos ayudará a idear soluciones y diseñar productos digitales que les encantará usar.
                        </p>
                        <Slider {...settings}>
                    {
                        items.map(item => {
                            return (
                                <div key={item.id} className='col-12'>
                                    <div className='card px-3 py-5 border-0'>
                                        <div className='responsive-img d-flex m-auto justify-content-center align-items-center'>
                                            <img src={require(`../../../assets/images/${item.icono}`)} alt='' />
                                        </div>
                                        <div className='card-body pt-4 px-0'>
                                            <h5 className='card-title'>{item.titulo}</h5>
                                            <p className='card-text pt-3'>{item.descripcion}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>
                    </div>
                    <div className="tab-pane fade" id="research" role='tab-panel' aria-labelledby='research-tab'>
                        <p className='py-4 px-2 text-center'>
                            Descubrir las necesidades y oportunidades declaradas por tus usuarios, nos ayudará a idear soluciones y diseñar productos digitales que les encantará usar.
                        </p>
                        <Slider {...settings}>
                    {
                        items.map(item => {
                            return (
                                <div key={item.id} className='col-12'>
                                    <div className='card px-3 py-5 border-0'>
                                        <div className='responsive-img d-flex m-auto justify-content-center align-items-center'>
                                            <img src={require(`../../../assets/images/${item.icono}`)} alt='' />
                                        </div>
                                        <div className='card-body pt-4 px-0'>
                                            <h5 className='card-title'>{item.titulo}</h5>
                                            <p className='card-text pt-3'>{item.descripcion}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>
                    </div>
                    <div className="tab-pane fade" id="consultoria" role='tab-panel' aria-labelledby='consultoria-tab'>
                        <p className='py-4 px-2 text-center'>
                            Descubrir las necesidades y oportunidades declaradas por tus usuarios, nos ayudará a idear soluciones y diseñar productos digitales que les encantará usar.
                        </p>
                        <Slider {...settings}>
                    {
                        items.map(item => {
                            return (
                                <div key={item.id} className='col-12'>
                                    <div className='card px-3 py-5 border-0'>
                                        <div className='responsive-img d-flex m-auto justify-content-center align-items-center'>
                                            <img src={require(`../../../assets/images/${item.icono}`)} alt='' />
                                        </div>
                                        <div className='card-body pt-4 px-0'>
                                            <h5 className='card-title'>{item.titulo}</h5>
                                            <p className='card-text pt-3'>{item.descripcion}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ExperienciaDigital