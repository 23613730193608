import React from "react";
import { Partners } from "../../components/Nosotros/Partners/Partners";
import { Experiencia } from "../../components/Nosotros/Experiencia/Experiencia";
import { HeroNosotros } from "../../components/Nosotros/HeroNosotros/HeroNosotros";
import { BajadaNosotros } from "../../components/Nosotros/BajadaNosotros/BajadaNosotros";
import { AdnNosotros } from "../../components/Nosotros/AdnNosotros/AdnNosotros";

export const Nosotros = () => {
  return (
    <>
      <HeroNosotros/>
      <BajadaNosotros/>
      <Experiencia/>
      <AdnNosotros/>
      <Partners/>
    </>
  );
};
