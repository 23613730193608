import Slider from 'react-slick';
import './Hero-Inicio.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const HeroInicio = () => {

  const itemsCarousel = [
    {
      id: 1,
      imagen: require('../../../assets/images/image1.png'),
      titulo: 'Nuestro propósito',
      descripcion: `Nos preocupamos por generar relaciones de largo plazo, generando una estrecha
                    relación de confianza y colaboración con nuestros clientes.`
    },
    {
      id: 2,
      imagen: require('../../../assets/images/image1.png'),
      titulo: 'Nuestro propósito',
      descripcion: `Nos preocupamos por generar relaciones de largo plazo, generando una estrecha 
                    relación de confianza y colaboración con nuestros clientes.`
    },
    {
      id: 3,
      imagen: require('../../../assets/images/image1.png'),
      titulo: 'Nuestro propósito',
      descripcion: `Nos preocupamos por generar relaciones de largo plazo, generando una estrecha 
                    relación de confianza y colaboración con nuestros clientes.`
    }
  ]

  let settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };

  return (
    <section id='heroInicio' className='heroInicio z-2 position-relative'>
      <div className='slider-carousel'>
        <Slider {...settings}>
          {
            itemsCarousel.map(({id, imagen, titulo, descripcion}) => {
              return (
                <div key={id} className='d-flex justify-content-center align-items-center position-relative'>
                  <img src={imagen} className='img-item vw-100 vh-100' alt="" />
                  <div className='caption-carousel position-absolute'>
                    <h1 className='text-center m-auto'>{titulo}</h1>
                    <p className='text-center px-4'>{descripcion}</p>
                  </div>
                  <div className='media-linkedin d-flex align-items-center flex-column-reverse gap-4 position-absolute'>
                    <a href="#" className='media-linkedin--title'>Siguenos</a>
                    <img src={require('../../../assets/images/LinkedIn_icon 1.png')} alt='LinkedIn icon' />
                  </div>
                </div>
              )
            })
          }
        </Slider>
      </div>
    </section>
  )
}

export default HeroInicio