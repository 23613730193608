import Slider from 'react-slick';
import { useState, useEffect } from 'react';
import './Soluciones.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Soluciones = () => {

    const [screenWidth, setScreenWidth] = useState(getCurrentDimension());

    function getCurrentDimension() { 
        return {
            width: window.innerWidth
        }
    };

    useEffect(() => {
        const updateDimension = () => setScreenWidth(getCurrentDimension());
        window.addEventListener('resize', updateDimension);
        return (() => window.removeEventListener('resize', updateDimension));
    }, [screenWidth])


    let settings = {
        dots: true,
        infinite: true,
        speed: 800,
        slidesToShow: screenWidth.width < 992 ? 1 : 3,
        slidesToScroll: 1,
        arrows: false
    };

    return (
        <section id='soluciones' className='soluciones'>
            <div className='container'>
                <h1 className='soluciones-title text-center'>
                    Nuestras <span> soluciones </span> <br /> para tu negocio 
                </h1>
                <div className='d-flex align-items-center justify-content-center mx-4 mt-4 overflow-hidden'>
                    <ul className='nav nav-fill nav-underline flex-wrap flex-nowrap overflow-auto' role='tablist'>
                        <li className='nav-item' role='presentation'>
                            <a href="#" className='nav-link active' type='button' id='transformacion-tab' data-bs-toggle='pill' data-bs-target='#transformacion'
                                role='tab' aria-controls='pills-transformacion' aria-selected='true'>
                                Transformacón Digital y Tecnología
                            </a>
                        </li>
                        <li className='nav-item'>
                            <a href="#" className='nav-link' type='button' id='gestion-tab' data-bs-toggle='pill' data-bs-target='#gestion'
                                role='tab' aria-controls='pills-gestion' aria-selected='false'>
                                Gestión, Operaciones, Riesgo y Cumplimiento
                            </a>
                        </li>
                    </ul>
                </div>
                <div className='tab-content' id='pills-tabContent'>
                    <div className="tab-pane fade show active" id="transformacion" role='tab-panel' aria-labelledby='transformacion-tab'>

                        <div className='slider-carousel'>
                            <Slider {...settings}>
                                <div className='caption-carousel d-flex flex-column justify-content-center align-items-start gap-4 pt-5'>
                                    <div className='responsive-img d-flex justify-content-center align-items-center'>
                                        <img src={require('../../../assets/images/responsive.png')} alt="" />
                                    </div>
                                    <h1 className='px-3'>
                                        Experiencia digital y desarrollo de proyectos    
                                    </h1>
                                    <p className='px-3'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                                        incididunt ut labore et dolore magna aliqua.
                                    </p>
                                    <a href='#' className='me-auto px-3'>Saber Más</a>
                                </div>
                                <div className='caption-carousel d-flex flex-column justify-content-center align-items-start gap-4 pt-5'>
                                    <div className='responsive-img d-flex justify-content-center align-items-center'>
                                        <img src={require('../../../assets/images/Ai-robotics-brain.png')} alt="" />
                                    </div>
                                    <h1 className='px-3'>
                                        Automatización de procesos
                                    </h1>
                                    <p className='px-3'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                                        incididunt ut labore et dolore magna aliqua.
                                    </p>
                                    <a href='#' className='me-auto px-3'>Saber Más</a>
                                </div>
                                <div className='caption-carousel d-flex flex-column justify-content-center align-items-start gap-4 pt-5'>
                                    <div className='responsive-img d-flex justify-content-center align-items-center'>
                                        <img src={require('../../../assets/images/Vector.png')} alt="" />
                                    </div>
                                    <h1 className='px-3'>
                                        Soluciones tecnológicas, proyectos y staffing TI
                                    </h1>
                                    <p className='px-3'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                                        incididunt ut labore et dolore magna aliqua.
                                    </p>
                                    <a href='#' className='me-auto px-3'>Saber Más</a>
                                </div>                                
                                <div className='caption-carousel d-flex flex-column justify-content-center align-items-start gap-4 pt-5'>
                                    <div className='responsive-img d-flex justify-content-center align-items-center'>
                                        <img src={require('../../../assets/images/Vector.png')} alt="" />
                                    </div>
                                    <h1 className='px-3'>
                                        Soluciones tecnológicas, proyectos y staffing TI
                                    </h1>
                                    <p className='px-3'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                                        incididunt ut labore et dolore magna aliqua.
                                    </p>
                                    <a href='#' className='me-auto px-3'>Saber Más</a>
                                </div>                                
                            </Slider>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="gestion" role='tab-panel' aria-labelledby='gestion-tab'></div>
                </div>
            </div>
        </section>
    )
}

export default Soluciones