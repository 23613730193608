import './Servicio.css';

const Servicio = () => {
    return (
        <section id='servicio' className='servicio d-flex'>
            <div className='container m-auto'>
                <div className='row'>
                    <div className='nuestro-servicio ps-4 pe-5 col-12 col-lg-6 d-flex ps-lg-5 pe-lg-3 flex-column gap-2'>
                        <span>Nuestro servicio</span>
                        <h2>
                            Emoción y
                            <span> Perzonalización </span>
                            <br />
                            que encanta
                        </h2>
                        <p className='pe-xxl-5'>
                            Las emociones y la ultra personalización están dando forma al comportamiento de los consumidores de hoy. Por eso
                            diseñamos experiencias de usuario centradas en lo humano, optimizando cada punto de experiencia digital con
                            propuestas diferenciadoras que desafían al contexto.
                        </p>
                    </div>
                    <div className='steps mt-3 d-none d-lg-block col-lg-6 position-relative'>
                        <div className='box left-box'>
                            <span className='position-absolute badge-service rounded-circle'>1</span>
                            <div className='text-box'>
                                <p className='m-0 text-end'>
                                    Creamos <span> experiencias </span><br /> memorables.
                                </p>
                            </div>
                        </div>
                        <div className='box right-box'>
                            <span className='position-absolute badge-service rounded-circle'>2</span>
                            <div className='text-box'>
                                <p className='m-0 text-start'>
                                    Identificamos el Digital <br />  <span> Customer Journey </span>
                                </p>
                            </div>
                        </div>
                        <div className='box left-box'>
                            <span className='position-absolute badge-service rounded-circle'>3</span>
                            <div className='text-box'>
                                <p className='m-0 text-end'>
                                    Buscamos un <span> diseño <br /> accesible e impresionante </span>
                                </p>
                            </div>
                        </div>
                        <div className='box right-box'>
                            <span className='position-absolute badge-service rounded-circle'>4</span>
                            <div className='text-box'>
                                <p className='m-0 text-start'>
                                    Mejoramos la <br /> adquisición y <span> lealtad de <br /> clientes </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Servicio