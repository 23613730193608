import Slider from 'react-slick';
import { useRef } from 'react';
import './Proyectos.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Proyectos = () => {

    const ref = useRef(null);

    const handleNextSlide = () => {
        ref.current.slickNext();
    };

    const handlePrevSlide = () => {
        ref.current.slickPrev();
    };

    let settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true
    };

    return (
        <section id='proyectos' className='proyectos z-2 position-relative'>
            <div className='container position-relative'>
                <Slider ref={ref} {...settings} >
                    <div className='row d-flex w-auto'>
                        <div className="col-lg-4 d-none d-lg-flex"></div>
                        <div className='nuestro-trabajo d-flex flex-column col-12 col-lg-4 pt-lg-0 px-3 px-lg-0 order-1 order-lg-2'>
                            <h1 className='title pt-5'> Algunos de <br className='d-flex d-md-none' /> 
                                <span> Nuestros </span>  <br className='d-none d-md-flex' /> Trabajos 
                            </h1>
                            <p className='subtitle pt-3 pe-xl-5'> Desarrollo APP para ejecutivos y clientes: </p>
                            <p className='date-company'> <strong> Financoop </strong> / Junio 06, 2023</p>
                        </div>
                        <div className='descripcion-proyecto d-flex flex-column col-12 col-lg-4 order-3 order-lg-3'>
                            <p className='descripcion ps-1 pe-xl-4'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna
                                aliqua ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididun...
                            </p>
                            <a className='ver-proyecto' href='#'>Ver Proyecto</a>
                        </div>
                    </div>
                    <div className='row d-flex w-auto'>
                        <div className="col-lg-4 d-none d-lg-flex"></div>
                        <div className='nuestro-trabajo d-flex flex-column col-12 col-lg-4 pt-lg-0 px-3 px-lg-0 order-1 order-lg-2'>
                            <h1 className='title pt-5'> Algunos de <br className='d-flex d-md-none' /> 
                                <span> Nuestros </span>  <br className='d-none d-md-flex' /> Trabajos 
                            </h1>
                            <p className='subtitle pt-3 pe-xl-5'> Desarrollo APP para ejecutivos y clientes: </p>
                            <p className='date-company'> <strong> Financoop </strong> / Junio 06, 2023</p>
                        </div>
                        <div className='descripcion-proyecto d-flex flex-column col-12 col-lg-4 order-3 order-lg-3'>
                            <p className='descripcion ps-1 pe-xl-4'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna
                                aliqua ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididun...
                            </p>
                            <a className='ver-proyecto' href='#'>Ver Proyecto</a>
                        </div>
                    </div>
                </Slider>
                <div className='cellphone-1 d-none d-lg-flex position-absolute'></div>
            </div>
            <div className='cellphone-1 d-flex d-lg-none position-absolute'></div>
            <div className='projects-items overflow-auto d-lg-flex d-none'>
                <div className='projects-item'>
                    <img src={require('../../../assets/images/Project1.png')} alt='' />
                </div>
                <div className='projects-item'>
                    <img src={require('../../../assets/images/Project2.png')} alt='' />
                </div>
                <div className='projects-item'>
                    <img src={require('../../../assets/images/Project3.png')} alt='' />
                </div>
                <div className='projects-item'>
                    <img src={require('../../../assets/images/Project2.png')} alt='' />
                </div>
            </div>
        </section>
    )
}

export default Proyectos