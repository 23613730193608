import HeroServicios from '../../components/Servicios/Hero-Servicios/Hero-Servicios';
import Servicio from '../../components/Servicios/Servicio/Servicio';
import Experiencias from '../../components/Servicios/Experiencias/Experiencias';
import ExperienciaDigital from '../../components/Servicios/Experiencia-Digital/Experiencia-Digital';
import Numeros from '../../components/Servicios/Numeros/Numeros';
import ComoTrabajamos from '../../components/Servicios/Como-Trabajamos/Como-Trabajamos';


const Servicios = () => {
  return (
    <>
      <HeroServicios />
      <Servicio />
      <Experiencias />
      <ExperienciaDigital />
      <ComoTrabajamos />
      <Numeros />
    </>
  )
}

export default Servicios