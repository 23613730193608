import React from "react";
import "./AdnNosotros.css";

export const AdnNosotros = () => {
  return (
    <section className="container section_adn_nosotros">
      <div className="section_adn_nosotros-header">
        <span>LO QUE NOS MUEVE</span>
        <h3>Nuestro ADN</h3>
        <small>
          Descubrir las necesidades y oportunidades declaradas por tus usuarios,
          nos ayudará a idear soluciones
        </small>
        <small>y diseñar productos digitales que les encantará usar.</small>
      </div>
      <div className="section_adn_nosotros-timeline">
        <div className="section_adn_nosotros-timeline_card">
          <div className="section_adn_nosotros-info">
            <h3 className="section_adn_nosotros-title">Confianza</h3>
            <p>
              No importa dónde te encuentres en el ciclo de desarrollo de un
              producto digital, podemos ayudarte a validar ideas y asegurarnos
              de que inviertas tiempo y energía en el momento correcto.
            </p>
          </div>
        </div>
        <div className="section_adn_nosotros-timeline_card">
          <div className="section_adn_nosotros-info">
            <h3 className="section_adn_nosotros-title">Confianza</h3>
            <p>
              No importa dónde te encuentres en el ciclo de desarrollo de un
              producto digital, podemos ayudarte a validar ideas y asegurarnos
              de que inviertas tiempo y energía en el momento correcto.
            </p>
          </div>
        </div>
        <div className="section_adn_nosotros-timeline_card">
          <div className="section_adn_nosotros-info">
            <h3 className="section_adn_nosotros-title">Confianza</h3>
            <p>
              No importa dónde te encuentres en el ciclo de desarrollo de un
              producto digital, podemos ayudarte a validar ideas y asegurarnos
              de que inviertas tiempo y energía en el momento correcto.
            </p>
          </div>
        </div>
        <div className="section_adn_nosotros-timeline_card">
          <div className="section_adn_nosotros-info">
            <h3 className="section_adn_nosotros-title">Confianza</h3>
            <p>
              No importa dónde te encuentres en el ciclo de desarrollo de un
              producto digital, podemos ayudarte a validar ideas y asegurarnos
              de que inviertas tiempo y energía en el momento correcto.
            </p>
          </div>
        </div>
        <div className="section_adn_nosotros-timeline_card">
          <div className="section_adn_nosotros-info">
            <h3 className="section_adn_nosotros-title">Confianza</h3>
            <p>
              No importa dónde te encuentres en el ciclo de desarrollo de un
              producto digital, podemos ayudarte a validar ideas y asegurarnos
              de que inviertas tiempo y energía en el momento correcto.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
