import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import Inicio from './pages/Inicio/Inicio';
import { Nosotros } from './pages/Nosotros/Nosotros';
import Servicios from './pages/Servicios/Servicios';
import Header from './components/Layout/Header/Header';
import Contacto from './components/Layout/Contacto/Contacto';
import Footer from './components/Layout/Footer/Footer';

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path='/' element={<Inicio />}/>
        <Route path='/nosotros' element={<Nosotros />}/>
        <Route path='/servicios' element={<Servicios />}/>
        <Route path='/proyectos' element={<Inicio />}/>
        <Route path="*" element={<Navigate to="/" />}/>
      </Routes>
      <Contacto />
      <Footer />
    </div>
  );
}

export default App;
