import './Numeros.css';

const Numeros = () => {

    window.onload = () => {

        let sectionNumeros = document.querySelector('#numeros');
        let counterItem = document.querySelectorAll('.counter');

        const observer = new IntersectionObserver((entries, observer) => {
            let [entry] = entries;
            if (!entry.isIntersecting) return;
    
            let speed = 200;
            counterItem.forEach((counter, index) => {
                function updateCounter() {
                    const targetNumber = + counter.dataset.target;
                    const initialNumber = + counter.innerText;
                    const incPerCount = targetNumber / speed;
                    if (initialNumber < targetNumber) {
                        counter.innerText = '+' + Math.ceil(initialNumber + incPerCount);
                        setTimeout(updateCounter, 40);
                    }
                }
                updateCounter();

                if (counter.parentElement.style.animation) {
                    counter.parentElement.style.animation = '';
                } else {
                    counter.parentElement.style.animation = `slide-up 0.3s ease forwards ${index / counterItem.length + 0.5}s`
                }

            });
        }, {
           root: null,
           threshold: 0.4
        });
        observer.observe(sectionNumeros);
    }

    return (
        <section id='numeros' className='numeros position-relative z-2'>
            <div className="container">
                <h1 className='text-center pt-5 px-2'>
                    Los <span> Números </span> Hablan de <br /> Nuestro Trabajo
                </h1>
                <div className="datos-trabajos row flex-column flex-md-row justify-content-center pt-4 pt-md-5 pb-5">
                    <div className="col-5 col-md-5 col-lg-2 text-center m-auto position-relative">
                        <h2 className='counter' data-target='125'>0</h2>
                        <p>Datos duros 01</p>
                        <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                            <span className="visually-hidden">New alerts</span>
                        </span>
                    </div>
                    <div className="col-5 col-md-5 col-lg-2 text-center m-auto position-relative">
                        <h2 className='counter' data-target='300'>0</h2>
                        <p>Datos duros 01</p>
                        <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                            <span className="visually-hidden">New alerts</span>
                        </span>
                    </div>
                    <div className="col-5 col-md-5 col-lg-2 text-center m-auto position-relative">
                        <h2 className='counter' data-target='27'>0</h2>
                        <p>Datos duros 01</p>
                        <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                            <span className="visually-hidden">New alerts</span>
                        </span>
                    </div>
                    <div className="col-5 col-md-5 col-lg-2 text-center m-auto position-relative">
                        <h2 className='counter' data-target='50'>0</h2>
                        <p>Datos duros 01</p>
                        <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                            <span className="visually-hidden">New alerts</span>
                        </span>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Numeros