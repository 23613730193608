import React from "react";
import "./HeroNosotros.css"
export const HeroNosotros = () => {
  return (
    <section id="hero" className="hero position-relative">
      <div className="container d-flex">
        <div className="media-linkedin d-flex align-items-center flex-column-reverse gap-4 position-absolute">
          <a className="media-linkedin--title" href="https://www.linkedin.com/company/vr-group-chile/" target="_blank" rel="noreferrer">Siguenos</a>
          <img
            src={require("../../../assets/images/LinkedIn_icon 1.png")}
            alt="LinkedIn icon"
          />
        </div>
        <div className="caption text-start ps-4 m-auto ms-lg-5 d-flex flex-column">
          <p className="m-0">VR GROUP</p>
          <h1>NUESTRO PROPOSITO</h1>
          <p className="m-0 bajadaHeroNosotros">
            Nos preocupamos por generar relaciones de largo plazo, generando una
            estrecha relación de confianza y colaboración con nuestros clientes.
          </p>
        </div>
        <div className="pills d-none d-lg-flex position-absolute"></div>
      </div>
      <div className="pills d-flex d-lg-none position-absolute"></div>
    </section>
  );
};
