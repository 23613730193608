import './Footer.css';

const Footer = () => {
    return (
        <div id='footer' className='footer px-md-5 px-3'>
            <footer className='d-flex justify-content-center align-items-center py-3'>
                <img className='img-logo-footer' src={require('../../../assets/images/logo-vr-group 2.png')} alt='Vrgroup' />
                <ul className='justify-content-evenly list-unstyled d-flex gap-2 align-items-center my-auto ms-auto'>
                    <li className='align-items-center gap-2 d-none d-lg-flex me-3'>
                        <span className='hablemos-text'>Hablemos</span>
                        <img src={require('../../../assets/images/message-icon.png')} alt='message-icon' />
                    </li>
                    <li className='d-flex'>
                        <span className='partnerships-text'>Partnerships</span>
                    </li>
                    <li className='d-flex'>
                        <img className='appian' src={require('../../../assets/images/appian.png')} alt='' />
                    </li>
                    <li className='d-flex'>
                        <img className='aira' src={require('../../../assets/images/aira.png')} alt='' />
                    </li>
                </ul>
            </footer>
        </div>
    )
}

export default Footer