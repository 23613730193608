import React from 'react';
import './Ayuda.css';

const Ayuda = () => {

  window.onload = () => {
    const middleContent = document.querySelectorAll('.middle-content');
    function triggerAnimation(entries) {
      entries.forEach(entry => {
        const rectangle = entry.target.querySelector('.podemos-ayudar-ayuda');
        rectangle?.classList.toggle('unset1', entry.isIntersecting);
      });
    }
    
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.3
    }
    
    const observer = new IntersectionObserver(triggerAnimation, options);
    
    middleContent.forEach(rect => {
      observer.observe(rect);
    });
  }

  return (
    <section id='ayuda' className='ayuda position-relative'>
      <div className='container'>
        <div className='row d-flex justify-content-center'>
          <div className='col-12 red-line d-block d-lg-none'></div>
          <h2 className='podemos-ayudar--title text-center px-3 d-block d-lg-none'>¿Te podemos ayudar?</h2>
          <div className='col-12 col-lg-5'>
            <div className='services-operations-risk d-flex flex-column align-items-center pt-5 px-2 px-lg-4 px-xl-5'>
              <img className='' src={require('../../../assets/images/darts.png')} alt='darts' />
              <span className='mt-5 px-xxl-5'>Servicio en Gestión, Operaciones, Riesgo y Cumplimiento</span>
              <p className='mt-3 px-xxl-5'>
                Apoyamos a nuestros clientes en la ejecución de la visión estratégica, transformando sus
                operaciones, mejorando sus
                procesos de gestión y trayendo las oportunidades del futuro al mundo de hoy.
              </p>
            </div>
          </div>
          <div className='middle-content col-2'>
            <div className='podemos-ayudar-ayuda p-4 justify-content-center d-none d-lg-flex' data-animation='down-1'>
              <p className='podemos-ayudar-ayuda-text'>¿Te podemos ayudar?</p>
            </div>
          </div>
          <div className='col-12 col-lg-5'>
            <div className='services-consultory-transformation d-flex flex-column align-items-center pt-5 px-2 px-lg-4 px-xl-5'>
              <img className='' src={require('../../../assets/images/computer-graphic.png')} alt='computer-graphic' />
              <span className='mt-5 px-xxl-5'>Servicios y Consultoría en Transformación Digital y Tecnología.</span>
              <p className='mt-3 px-xxl-5'>
                Apoyamos a nuestros clientes en la transformación digital de sus negocios, mediante
                servicios y herramientas
                tecnológicas que les permitan ser competitivos y flexibles, con soluciones a la medida de
                sus objetivos.
              </p>
            </div>
          </div>
          <div className='col-12 red-line-bottom d-block d-lg-none'></div>
          <div className='cellphone'></div>
        </div>
      </div>
    </section>
  )
}

export default Ayuda