import React from 'react'
import { Partner1Svg, Partner2Svg, Partner3Svg } from '../../../assets/svg/PartnersSvg'
import './Partners.css'
export const Partners = () => {
  return (
    <section className='section_partners'>
      <h4>Partners Tecnológicos</h4>
      <div className='section_partners_IMGS'>
        <Partner1Svg />
        <Partner2Svg />
        <Partner3Svg />
      </div>
    </section>
  )
}
