import './Como-Trabajamos.css';
import { useState, useEffect } from 'react';

const ComoTrabajamos = () => {

    const [screenWidth, setScreenWidth] = useState(getCurrentDimension());

    let steps = [
        {
            id: 1,
            title: 'Problemática / Idea inicial',
            TooltipTitle: 'Diseñar y testear',
            TooltipDescription: `- Gestión e implementación del desarrollo de la solución en modalidad Ágil o Tradicional.
            - Métricas de Desarrollo Proceso, Equipo e Impacto.
            - Certificación de la solución.`
        },
        {
            id: 2,
            title: 'Desarrollo de la Solución',
            TooltipTitle: 'Diseñar y testear',
            TooltipDescription: `- Gestión e implementación del desarrollo de la solución en modalidad Ágil o Tradicional.
            - Métricas de Desarrollo Proceso, Equipo e Impacto.
            - Certificación de la solución.`
        },
        {
            id: 3,
            title: 'Producción',
            TooltipTitle: 'Diseñar y testear',
            TooltipDescription: `- Gestión e implementación del desarrollo de la solución en modalidad Ágil o Tradicional.
            - Métricas de Desarrollo Proceso, Equipo e Impacto.
            - Certificación de la solución.`
        },
        {
            id: 4,
            title: 'Delivery continuo',
            TooltipTitle: 'Diseñar y testear',
            TooltipDescription: `- Gestión e implementación del desarrollo de la solución en modalidad Ágil o Tradicional.
            - Métricas de Desarrollo Proceso, Equipo e Impacto.
            - Certificación de la solución.`
        },
        {
            id: 5,
            title: 'Lorem Ipsum',
            TooltipTitle: 'Diseñar y testear',
            TooltipDescription: `- Gestión e implementación del desarrollo de la solución en modalidad Ágil o Tradicional.
            - Métricas de Desarrollo Proceso, Equipo e Impacto.
            - Certificación de la solución.`
        }
    ];

    function getCurrentDimension() {
        return {
            width: window.innerWidth
        }
    };

    useEffect(() => {
        const updateDimension = () => setScreenWidth(getCurrentDimension());
        window.addEventListener('resize', updateDimension);
        return (() => window.removeEventListener('resize', updateDimension));
    }, [screenWidth]);

    return (
        <section id='comoTrabajamos' className='comoTrabajamos'>
            <h1 className='comoTrabajamos-title py-5 text-center'>Cómo Trabajamos</h1>
            <div className='content'>
                <ul className='timeline'>
                    {
                        steps.map(step => {
                            return (
                                <li key={step.id} className={
                                    (step.id === 5 && screenWidth.width < 992) ? 'd-none' : ''
                                }>
                                    <div className='box-info'>
                                        <span className='text-number'>{step.id}</span>
                                        <p className='text-desc'>{step.title}</p>
                                        <div className='open-tooltip'>+
                                            <span className='tooltiptext'>
                                                <h1>{step.TooltipTitle}</h1>
                                                <p>{step.TooltipDescription}</p>
                                            </span>
                                        </div>
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </section>
    )
}

export default ComoTrabajamos;