import React from "react";
import "./BajadaNosotros.css"
export const BajadaNosotros = () => {
  return (
    <section className="container section_bajada_nosotros">
      <span>CONÓCENOS</span>
      <h2>
        Somos una <strong>Consultora Tecnológica</strong> en transformación
        digital
      </h2>
      <p>
        Los desafíos y objetivos de nuestros clientes son también los nuestros.
        Generamos soluciones simples y de gran impacto. Creemos que la
        simplicidad es un factor clave en el éxito de los negocios.
      </p>
    </section>
  );
};
