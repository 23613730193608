import HeroInicio from '../../components/Inicio/Hero-Inicio/Hero-Inicio';
import Ayuda from '../../components/Inicio/Ayuda/Ayuda';
import Soluciones from '../../components/Inicio/Soluciones/Soluciones';
import Testimonios from '../../components/Inicio/Testimonios/Testimonios';
import Proyectos from '../../components/Inicio/Proyectos/Proyectos';

const inicio = () => {
  return (
    <>
      <HeroInicio />
      <Ayuda />
      <Soluciones />
      <Testimonios />
      <Proyectos />
    </>
  )
}

export default inicio