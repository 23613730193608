import Slider from 'react-slick';
import { useRef, useState, useEffect } from 'react';
import './Testimonios.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Testimonios = () => {

    const [screenWidth, setScreenWidth] = useState(getCurrentDimension());
    const ref = useRef(null);

    const items = [
        {
            id: 1,
            image: 'bcoEstado.png'
        },
        {
            id: 2,
            image: 'puerto.png'
        },
        {
            id: 3,
            image: 'caja.png'
        },
        {
            id: 4,
            image: 'bice.png'
        },
        {
            id: 5,
            image: 'unicard.png'
        }
    ]

    const handleNextSlide = () => {
        ref.current.slickNext();
    };

    const handlePrevSlide = () => {
        ref.current.slickPrev();
    };

    function getCurrentDimension() { 
        return {
            width: window.innerWidth
        }
    };

    useEffect(() => {
        const updateDimension = () => setScreenWidth(getCurrentDimension());
        window.addEventListener('resize', updateDimension);
        return (() => window.removeEventListener('resize', updateDimension));
    }, [screenWidth])

    let settings = {
        dots: false,
        infinite: true,
        slidesToShow: screenWidth.width >= 992 ? 5 : 3,
        slidesToScroll: 1,
        vertical: screenWidth.width >= 992 ? false : true,
        verticalSwiping: true,
        arrows: false
    };

    return (
        <section id='testimonios' className='testimonios'>
            <div className='container'>
                <h1 className='main-title text-center'>Confían en nosotros</h1>
                <div className='slider-carousel mt-5 position-relative'>
                    <Slider ref={ref} {...settings} >
                        {
                            items.map(item => {
                                return (
                                    <div key={item.id} className='d-flex align-items-center justify-content-center py-3'>
                                        <img src={require(`../../../assets/images/${item.image}`)} alt='' />
                                    </div>
                                )
                            })
                        }
                    </Slider>
                    {
                        items.length !== settings.slidesToShow &&
                        <div className='button-container d-flex justify-content-center align-items-center gap-4 pt-4'>
                            <button className="bg-transparent border-0" onClick={handlePrevSlide}>
                                <img src={require('../../../assets/images/Arrow-left.png')} alt="" />
                            </button>
                            <button className="bg-transparent border-0" onClick={handleNextSlide}>
                                <img src={require('../../../assets/images/Arrow-right.png')} alt="" />
                            </button>
                        </div>
                    }
                </div>
                <div className='row testimonios-clientes'>
                    <div className='col-12 col-lg-3 px-4'>
                        <h3 className='testimonios-clientes-title'>
                            Testimonios de <br />
                            <span> Nuestros </span> Clientes
                        </h3>
                    </div>
                    <div className='col-12 col-lg-5 pt-5 pt-lg-0 d-flex flex-column px-4 order-lg-3 pe-lg-0'>
                        <img className='comillas ps-lg-5 ms-lg-3' src={require('../../../assets/images/comillas.png')} alt='' />
                        <p className='testimonios-clientes-text pt-5 px-lg-5 ms-lg-3'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna
                            aliqua ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna
                            aliqua tempor incididunt ut labore et dolore.
                        </p>
                        <span className='cliente pt-5 ps-lg-5 ms-lg-3'>
                            <strong> José Pablo, Coordinador General </strong>
                            <br />
                            Puerto de Ideas
                        </span>
                        <a className='link-project pt-4 ps-lg-5 ms-lg-3' href=''>Ver proyecto</a>
                    </div>
                    <div className='col-12 col-lg-4 pt-5 pt-lg-0 px-0'>
                        <div className='content-picture-person position-relative m-auto'>
                            <img src={require('../../../assets/images/image33.png')} className='img-testimonio-person' alt='' />
                            <img src={require('../../../assets/images/Group257.png')} className='iphone pe-lg-4' alt='' />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Testimonios