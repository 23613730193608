import './Hero-Servicios.css';

const HeroServicios = () => {
  return (
    <section id='hero' className='hero position-relative'>
        <div className='container d-flex'>
            <div className='media-linkedin d-flex align-items-center flex-column-reverse gap-4 position-absolute'>
                <a className='media-linkedin--title'>Siguenos</a>
                <img src={require('../../../assets/images/LinkedIn_icon 1.png')} alt='LinkedIn icon' />
            </div>
            <div className='caption text-start ps-4 m-auto ms-lg-5 d-flex flex-column'>
                <p className='m-0'>Transformación digital y tecnología</p>
                <h1>Diseño de <br className='d-none d-lg-block' /> experiencia digital</h1>
                <p className='m-0'>Diseño centrado en el usuario</p>
            </div>
            <div className='laptop-cellphone d-none d-lg-flex position-absolute'></div>
        </div>
        <div className='laptop-cellphone d-flex d-lg-none position-absolute'></div>
    </section>
  )
}

export default HeroServicios