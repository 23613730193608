import { useState } from 'react';
import './Contacto.css';

const Contacto = () => {

    const [nombre, setNombre] = useState('');
    const [correo, setCorreo] = useState('');
    const [servicio, setServicio] = useState('');
    const [comentario, setComentario] = useState('');

    window.onscroll = () => {
        const middleContent = document.querySelectorAll('.red-content');
        function triggerAnimation(entries) {
            entries.forEach(entry => {
                const rectangle = entry.target.querySelector('.lets-talk');
                rectangle?.classList.toggle('unset', entry.isIntersecting);
            });
        }

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.6
        }

        const observer = new IntersectionObserver(triggerAnimation, options);

        middleContent.forEach(rect => {
            observer.observe(rect);
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const objectoContacto = {
            nombre: nombre,
            correo: correo,
            servicio: servicio,
            comentario: comentario
        };

        let contacto = JSON.parse(localStorage.getItem('contacto')) || [];
        contacto = [...contacto, objectoContacto];

        localStorage.setItem('contacto', JSON.stringify(contacto));
    }

    return (
        <section id='contacto' className='contacto'>
            <div className='container'>
                <div className='row'>
                    <div className='red-line-block d-flex d-lg-none flex-column gap-2'>
                        <div className='red-line m-auto'></div>
                        <img src={require('../../../assets/images/Union.png')} className='m-auto' alt='' />
                        <span className='text-hablemos text-center'>Hablemos</span>
                    </div>
                    <div className='contacto-user col-12 col-lg-6 col-xl-5 d-flex flex-column pb-4 px-4 m-auto'>
                        <span className='contacto-title mt-5'>Contacto</span>
                        <p className='mt-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                        <p>(+56) 9 6192 8820</p>
                        <p>contacto@gmail.com</p>
                    </div>
                    <div className='red-content col-xl-2'>
                        <div className='lets-talk d-none d-xl-flex flex-column align-items-center vh-100' data-animation='down'>
                            <img src={require('../../../assets/images/message-icon.png')} width='40' height='36' alt='' />
                            <p>Hablemos</p>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6 col-xl-5 pb-5 px-4 m-auto'>
                        <form className='mt-lg-5' onSubmit={handleSubmit}>
                            <div className='mb-3'>
                                <input 
                                        type='text' 
                                        className='form-control' 
                                        id='nombre'
                                        placeholder='Nombre' 
                                        value={nombre}
                                        onChange={(e) => setNombre(e.target.value)}
                                />
                            </div>
                            <div className='mb-3'>
                                <input 
                                        type='email'   
                                        className='form-control' 
                                        id='exampleFormControlInput1'
                                        placeholder='Correo' 
                                        value={correo}
                                        onChange={(e) => setCorreo(e.target.value)}
                                />
                            </div>
                            <div className='mb-3'>
                                <select 
                                    className='form-select' 
                                    aria-label='Default select example'
                                    onChange={(e) => setServicio(e.target.value)}
                                    value={servicio}
                                    >
                                    <option defaultValue>¿Qué servicios buscas?</option>
                                    <option value='1'>One</option>
                                    <option value='2'>Two</option>
                                    <option value='3'>Three</option>
                                </select>
                            </div>
                            <div className='mb-3'>
                                <textarea 
                                        className='form-control' 
                                        id='exampleFormControlTextarea1'
                                        placeholder='Cuéntanos sobre tu proyecto y agendemos una reunión.'
                                        rows='5'
                                        value={comentario}
                                        onChange={(e) => setComentario(e.target.value)}
                                        >
                                </textarea>
                            </div>
                            <button type='submit' className='btn-send rounded-5'>Enviar</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contacto