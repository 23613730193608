import Slider from 'react-slick';
import { useRef, useState, useEffect } from 'react';
import './Experiencias.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Experiencias = () => {

    const [screenWidth, setScreenWidth] = useState(getCurrentDimension());
    const ref = useRef(null);

    const items = [
        {
            id: 1,
            title: 'Experiencias humanas',
            icono: 'Ai-robotics-brain.png',
            descripcion: `Logra un diseño centrado en las personas, su experiencia y sus necesidades manifiestas.`
        },
        {
            id: 2,
            title: 'Visión balanceada',
            icono: 'Vector.png',
            descripcion: `Armoniza la visión de los usuarios, del mercado y el negocio para crear experiencias digitales sorprendentes.`
        },
        {
            id: 3,
            title: 'Experiencias humanas',
            icono: 'Ai-robotics-brain.png',
            descripcion: `Logra un diseño centrado en las personas, su experiencia y sus necesidades manifiestas.`
        },
    ]

    const handleNextSlide = () => {
        ref.current.slickNext();
    };

    const handlePrevSlide = () => {
        ref.current.slickPrev();
    };


    function getCurrentDimension() { 
        return {
            width: window.innerWidth
        }
    };

    useEffect(() => {
        const updateDimension = () => setScreenWidth(getCurrentDimension());
        window.addEventListener('resize', updateDimension);
        return (() => window.removeEventListener('resize', updateDimension));
    }, [screenWidth])

    let settings = {
        dots: true,
        infinite: true,
        speed: 800,
        slidesToShow: screenWidth.width < 1200 ? 1 : 2,
        slidesToScroll: 1,
        arrows: false
    };

    return (
        <section id='experiencias' className='experiencias d-flex'>
            <div className='container my-lg-auto position-relative'>
                <div className='row'>
                    <div className='beneficios-content col-12 col-lg-6 col-xl-5 col-xxl-4 px-4 pt-5'>
                        <span className='beneficios-text'>Beneficios</span>
                        <h1>Experiencias intuitivas que <span> potencian </span> tu negocio</h1>
                        <p className='pt-3'>
                            Nuestra amplia gama de servicios UX cubre desde la investigación, diseño de interacciones, auditorías de usabilidad,
                            hasta un completo Laboratorio UX, que coloca al usuario final al centro, para potenciar tareas y objetivos de negocio.
                        </p>
                        <a className='py-1 px-3 rounded-4 d-flex align-items-center gap-3'>
                            <span>Hablemos</span>
                            <img src={require('../../../assets/images/message-icon.png')} alt='' />
                        </a>
                    </div>
                    <div className='col-12 col-lg-6 px-4 col-xl-7 col-xxl-8 px-lg-5 pt-5 d-flex flex-column justify-content-lg-end'>
                        <Slider ref={ref} {...settings}>
                            {
                                items.map(item => {
                                    return (
                                        <div key={item.id} className='item d-flex flex-column justify-content-center align-items-start position-relative px-2'>
                                            <img src={require(`../../../assets/images/${item.icono}`)} className='m-auto d-flex' alt='...' />
                                            <h1 className='pt-4'>{item.title}</h1>
                                            <p className='pt-4'>{item.descripcion}</p>
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                        <div className='button-container d-none d-lg-flex justify-content-center align-items-center gap-4 pt-4 position-absolute'>
                            <button className="bg-transparent border-0" onClick={handlePrevSlide}>
                                <img src={require('../../../assets/images/chevron.svg')} alt="" />
                            </button>
                            <button className="bg-transparent border-0" onClick={handleNextSlide}>
                                <img src={require('../../../assets/images/Arrow-right.png')} alt="" />
                            </button>
                        </div>             
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Experiencias