import React, { useEffect, useMemo, useState } from "react";
import Slider from 'react-slick';
import imgExperiencia1 from "../../../assets/images/experiencia1.png";
import { LinkedinIcon, TwitterIcon } from "../../../assets/svg/ExperienciaSvg";
import "./Experiencia.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Experiencia = () => {
  const [screenWidth, setScreenWidth] = useState(getCurrentDimension());

  function getCurrentDimension() { 
    return {
        width: window.innerWidth
    }
  };

  useEffect(() => {
    const updateDimension = () => setScreenWidth(getCurrentDimension());
    window.addEventListener('resize', updateDimension);
    return (() => window.removeEventListener('resize', updateDimension));
}, [screenWidth])

  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: screenWidth.width >= 992 ? 3 : 1,
    slidesToShow: screenWidth.width >= 992 ? 3 : 1,
    arrows: false,
};

  const [trabajadores] = useState([
    {
      titulo: "Socio Fundador",
      nombre: "Socio Fundador",
      img: imgExperiencia1,
      links: ["#", "#"],
    },
    {
      titulo: "Socio Fundador",
      nombre: "Socio Fundador",
      img: imgExperiencia1,
      links: ["#", "#"],
    },
    {
      titulo: "Socio Fundador",
      nombre: "Socio Fundador",
      img: imgExperiencia1,
      links: ["#", "#"],
    },
    {
      titulo: "Socio Fundador",
      nombre: "Socio Fundador",
      img: imgExperiencia1,
      links: ["#", "#"],
    },
    {
      titulo: "Socio Fundador",
      nombre: "Socio Fundador",
      img: imgExperiencia1,
      links: ["#", "#"],
    },
    {
      titulo: "Socio Fundador",
      nombre: "Socio Fundador",
      img: imgExperiencia1,
      links: ["#", "#"],
    },
  ])
  useMemo(() => trabajadores, [trabajadores])

  //TODO HACER RESPECTIVO COMPONENTE PARA LAS CARDS

  return (
    <section className="section_experiencia">
      <div className="container">
        <h3>
          Experiencia <span>del Equipo</span>
        </h3>
        <Slider {...settings}>
          {trabajadores.map(({ titulo, nombre, img, links }, index) => (
            <div className="section_experiencia_card" key={index}>
              <div className="section_experiencia_card-header">
                <div className="section_experiencia_card-header_img">
                  <img src={img} alt="imgExperiencia1" />
                </div>
                <div className="section_experiencia_card-header_hover">
                  <a
                    href={links[0]}
                    className="experiencia_card-link"
                  >
                    <LinkedinIcon />
                  </a>
                  <a
                    href={links[1]}
                    className="experiencia_card-link"
                  >
                    <TwitterIcon />
                  </a>
                </div>
              </div>
              <div className="section_experiencia_card-body">
                <small>{titulo}</small>
                <strong>{nombre}</strong>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};
